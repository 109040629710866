import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface SEOProps {
  title?: string;
  url?: string;
  description?: string;
  image?: string;
}

interface QueryProps {
  site: { siteMetadata: SEOProps };
}

const getMetadata = (siteMetadata: SEOProps, props: SEOProps) => {
  const {
    title = siteMetadata.title,
    url = siteMetadata.url,
    description = siteMetadata.description,
    image = siteMetadata.image,
  } = props;

  return { title, url, description, image };
};

const SEO: React.FC<SEOProps> = (props) => {
  const { site } = useStaticQuery<Required<QueryProps>>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
            image
          }
        }
      }
    `
  );
  const { title, url, description, image } = getMetadata(site.siteMetadata, props);

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <meta property="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      <meta name="google-site-verification" content="D1HbqvJh75p-3snZtrxMujBMfTXXJjlNfx199NvUfeQ" />
    </Helmet>
  );
};

export default SEO;
